import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  user_search (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/search?' + query;
    } else {
      route = 'v1/user/search';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  }
};
