<template>
  <div>
    <b-row>
      <b-col cols="8">
        <b-form-input type="text" v-model="p_searchResult.search_text"></b-form-input>
      </b-col>
      <b-col cols="2">
        <b-button variant="outline-primary" v-if="p_searchResult.search_text" @click="f_userSearch()"> <i class="fa fa-search-plus"></i> </b-button>
      </b-col>
      <b-col cols="2">
        <b-button variant="outline-danger" v-if="p_searchResult.list.length > 0" @click="f_clearSearch()"> <i class="fa fa-trash"></i> </b-button>
      </b-col>
    </b-row>
    <template v-if="p_searchResult.list.length > 0">
      <b-row v-for="(result_item, result_item_index) in p_searchResult.list" :style="result_item_index === p_searchResult.selected_index ? 'cursor: pointer; color: green;' : 'cursor: pointer;'" @click="f_selectUser(result_item_index)">
        <b-col cols="12">
          <i v-if="result_item_index === p_searchResult.selected_index" class="fa fa-check"></i>
          {{ result_item.username }}, {{ result_item.mail }}
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { data as system_menu_list } from '@/options/system_menu_list';
import UserService from '@/services/user';
import { mapGetters } from 'vuex';
export default {
  name: 'UserSearch',
  computed: mapGetters({
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreDevice: 'StoreDevice',
    StoreCurrencyType: 'StoreCurrencyType',
  }),
  props: {
    p_searchResult: {
      type: Object,
      required: true
    },
    p_searchMode: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      d_searchMode: {
        'field_type': 'all', // username, all, mail, ve benzeri, smar
        'match_type': 'full' // full, include
      },
    }
  },
  created: function () {
    this.d_user = JSON.parse(localStorage.getItem('user'));
    if (this.p_searchMode) {
      this.d_searchMode = this.p_searchMode;
    }
  },
  mounted: function () {
    //
  },
  methods: {
    f_getRoutePath: function (target_route_name_en) {
      let route_path = '';
      for (let i in system_menu_list) {
        if (system_menu_list[i].route_name.en == target_route_name_en) {
          route_path = system_menu_list[i].route_path[this.StoreLang];
        }
        if (!route_path) {
          if (system_menu_list[i].list && system_menu_list[i].list.length) {
            for (let k in system_menu_list[i].list) {
              if (system_menu_list[i].list[k].route_name.en == target_route_name_en) {
                route_path = system_menu_list[i].list[k].route_path[this.StoreLang];
                break;
              }
            }
          }
        }
        if (route_path) {
          break;
        }
      }
      return route_path;
    },
    f_goCustomerService: function () {
      this.$router.push({ path: this.f_getRoutePath('customer_service'), query: {} });
    },
    f_selectUser: function (result_item_index) {
      this.p_searchResult.selected_index = result_item_index;
    },
    f_clearSearch: function () {
      this.p_searchResult.selected_index = '';
      this.p_searchResult.list = [];
    },
    f_userSearch: function () {
      this.p_searchResult.list = [];
      let query = 'field_type=' + this.d_searchMode.field_type;
      query += '&match_type=' + this.d_searchMode.match_type;
      let data = {
        'search_text': this.p_searchResult.search_text,
      };
      UserService.user_search(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            this.p_searchResult.list = resp.data.list;
            if (this.p_searchResult.list.length === 1) {
              this.p_searchResult.selected_index = 0;
            }
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
  }
}
</script>
